<template>
  <div class="wrap">
    <commonPageBanner
      url="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/Banner_tree.png"
    />
    <div class="content_area">
      <searchActivity
        v-model="queryParams.companyName"
        @search="search"
        :placeTitle="$t('enter_Activity_theme')"
        class="search-wrap"
      />
      <div class="big_box" v-loading='loading'>
        <template v-if="companyList.length != 0">
          <item
          v-for="(item, i) in companyList"
          :key="i"
          :item="item"
          :index="i"
          @countAdd="countAdd"
          class="item"
        ></item>
        </template>
         <noDataImg v-if="total == 0 && !loading"/>
      </div>
      <div class="my_pagination">
          <el-pagination
            :current-page="currentPage"
            :hide-on-single-page='true'
            @current-change="handleChange"
            background
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="total">
          </el-pagination>
      </div>
      
    </div>
  </div>
</template>

<script>
import commonPageBanner from "~hns/components/layouts/pageBanner";
import isLoadingTip from "@/baseComponents/isLoadingTip";
import isAllDataTip from "@/baseComponents/isAllDataTip";
import item from "~hns/components/acitvity/item";
import searchActivity from "~hns/components/acitvity/searchActivity";
export default {
  components: {
    commonPageBanner,
    searchActivity,
    isLoadingTip,
    isAllDataTip,
    item,
  },
 metaInfo() {
    return {
      title: this.$t("trainingActivities"),
    };
  },
  data() {
    return {
      pageSize:12,
      total: 0,
      currentPage: 1,
      companyList: [],
      loading: false,
      queryParams: {
        companyName: "",
      },
      companyName: "",
      filters: {
        city: {},
        unitType: null,
        checkYear: null,
        checkClass: [],
        checkCompaines: [],
        checkLine: [],
      },
      cityType: 0,
    };
  },
  watch: {
    "$route.query.parameter"() {
      if (this.$route.query.parameter) {
        this.queryParams.companyName = this._decode(
          this.$route.query.parameter
        ).searchVal;
      } else {
        this.queryParams.companyName = "";
      }
      this.currentPage = 1;
      this.getList();
    }
  },
  mounted() {
    if (this.$route.query.parameter) {
      this.queryParams.companyName = this._decode(
        this.$route.query.parameter
      ).searchVal;
    }
    this.getList();
    // window.addEventListener("scroll", this.scrollEvent, false);
  },
  destroyed() {
    // window.removeEventListener("scroll", this.scrollEvent, false);
  },
  methods: {
    handleChange(index) {
      console.log(index,999);
      this.currentPage = index;
      this.getList();
    },
    countAdd(row) {
      this.companyList[row.index].views += 1;
    },
    // 转数据
    handleData(obj) {
      let arr = [];
      for (let i in obj) {
        arr = arr.concat(obj[i]);
      }
      return arr;
    },
    async getList() {
      
      // window.removeEventListener("scroll", this.scrollEvent, false);
      //type 1是搜索赋值 2是下拉拼接
      this.loading = true;
      let params = {
        start: this.currentPage-1,
        limit: this.pageSize,
        is_top: 1,
        is_show: 1,
        source: this.PJSource,
        user_id: this.USER_INFO.id,
        name: this.queryParams.companyName,
        company_id: this.USER_INFO.company_id,
      };

      let res = await this.$store.dispatch(
        "API_index/association_event",
        params
      );
      if (res.success) {
        // window.addEventListener("scroll", this.scrollEvent, false);
        this.companyList = res.data;
        this.total = res.total;
        this.loading=false
        document.body.scrollTop = document.documentElement.scrollTop = 380;
      }
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    handleCurrentChange() {},
    scrollEvent() {
      if (
        document.documentElement.scrollTop +
          document.documentElement.clientHeight >=
        document.body.scrollHeight - 354
      ) {
        if (this.total != this.companyList.length) {
          this.getList(2);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background: #fafafa;
}

.content_area {
  .search-wrap {
    text-align: right;
    margin-bottom: 16px;
  }
}
.big_box {
  min-height: 400px;
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
}
.item {
  margin-right: 13px;
  margin-bottom: 20px;
}
.item:nth-child(4n) {
  margin-right: 0;
}
.loading_all_center {
    padding: 15px;
    width: 100%;
    text-align: center;
  }
.my_pagination{
  text-align: right;
  margin: 10px 0px 30px;
}

</style>
