<template>
  <div class="item">
    <el-image
      @click="toDetail"
      class="img cursor"
      style="width: 290px; height: 174px"
      :src="
        item.poster ? item.poster : LOCALE == 'en' ? default_img1 : default_img
      "
      fit="corver"
    ></el-image>
    <div class="time_num">
      <div class="time">{{ item.created_time | secondFormat("LLL") }}</div>
      <div class="num"><img :src="eye_img" alt="" /> {{ item.views }}</div>
    </div>
    <div
      class="tit cursor"
      @click="toDetail"
      :title="item.name_en | priorFormat(item.name_zh, LOCALE)"
    >
      <a :href="'/activityDetail' + item.id" target="_blank">
        {{ item.name_en | priorFormat(item.name_zh, LOCALE) }}</a
      >
    </div>
    <div class="bottom_loc">
      <img :src="address" alt="" />
      <span v-if="item.event_type == 1" class="des textOverflow">{{
        $t("onlineActivities")
      }}</span>
      <span
        v-if="item.event_type == 2"
        class="des textOverflow"
        :title="item.address_en | priorFormat(item.address_zh, LOCALE)"
        >{{ item.address_en | priorFormat(item.address_zh, LOCALE) }}</span
      >
    </div>
  </div>
</template>
<script>
export default {
  props: ["item", "index"],
  data() {
    return {
      eye_img:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaStatic/img/eye.png",
      address:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaStatic/img/address.png",
      default_img:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/active.png",
      default_img1:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/activeEn.png",
    };
  },
  methods: {
    toDetail() {
      this.$emit("countAdd", { index: this.index });
      let routeUrl = this.$router.resolve({
        path: "/activityDetail",
        query: {
          id: this.item.id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>
<style lang="less" scoped>
.item {
  width: 290px;
  height: 316px;
  background: #ffffff;
}
.item:nth-child(4) {
  margin-left: 0;
}
.tit {
  margin: 0 12px;
  padding-top: 10px;
  line-height: 20px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 266px;
  height: 51px;
  font-size: 16px;
  font-weight: bold;
  color: #4c4c4c;
  line-height: 22px;
}
.time_num {
  padding: 0 12px;
  padding-top: 9px;
  display: flex;
  justify-content: space-between;
  .time {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #909399;
    line-height: 17px;
  }
  .num {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #909399;
    line-height: 17px;
    display: flex;
    align-items: center;
    img {
      margin-right: 3px;
    }
  }
}
.bottom_loc {
  margin-top: 22px;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  img {
    width: 20px;
    height: 20px;
  }
  .des {
    width: 236px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #345292;
    line-height: 20px;
  }
}
</style>